@import './layout.scss';
@import './variables.scss';
@import './fonts.scss';
@import './stats.scss';
@import './account.scss';
@import './missing.scss';
@import './footer.scss';
@import './user-stats.scss';
@import './game-ended.scss';

.App {
  text-align: center;
  font-family: $main;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  h1 {
    font-family: $kaushan-script;
    margin: 0.5em;
  }
  small {
    margin-bottom: 0.5em;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

ul p {
  width: 100%;
  border-bottom: 1px solid;
  text-indent: 15px;
  margin: 1em 0;
  font-weight: bold;
}

table {
  width: 40vw;
}

td { 
  text-align: left;
  padding: 5px 10px;
  border: 1px solid #c1c1c1;
}

.scoresheets .scoresheet .MuiFormControlLabel-root {
  margin: 0;
}


.scoresheet p {
  text-align: left;
  margin: 0.5em 0 0;
}

.MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th {
  border: 1px solid #c1c1c1 !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.scoresheet-header .MuiTableRow-root:last-child td, .scoresheet-header .MuiTableRow-root:last-child th {
  border: 0 !important;
}

.tactics {
  display: flex;
}