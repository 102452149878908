.user-stats {
    position: relative;

    p {
        .user-stats-filter {
            margin-left: 0.5em !important;
        }
    }

    .MuiTableContainer-root {
        min-height: none;
    }

    .legend {
        border-top: 1px dashed #3f3f3f;
        padding: 1em;
        ul {
            text-align: left;
            padding-left: 1em;
            width: 90%;
            list-style: none;
        }
    }
}