@import './variables.scss';
@import './fonts.scss';
@import './buttons.scss';

// Base (Mobile upwards)
.App-header {
  padding: 0 1em;

  .navbar {
    font-family: $main;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    background-color: #efefef;

    a {
      color: black;
      padding: 0 1em;
      border-right: 1px solid #ccc;
      text-decoration: none;
    }
    :last-child {
      border-right: none;
    }
  }
}

section {
  padding-bottom: 60px;

  .body {
    padding: 0 2em;
  }

  .resume-game-info {
    display: flex;
    text-align: left;
    padding: 1em;

  }

  .resume-game-divider {
    margin: 1em 0;
  }

  .privacy-policy {
    text-align: left;
    
    h1, h2 {
      text-align: center;
    }

    ol {
      li {
        margin: 0.5em 0;
      }
    }

    .MuiAccordionSummary-content {

      p {
        display: flex;
        
        svg {
          margin-right: 0.5em;
        }
      }
    }
  }

  .privacy-policy.update {
    margin: 1em auto;
    
  }

  .stats-divider {
    width: 100%;
    border-color: #c1c1c1;
    margin: 1em 0;
  }

  .stats-form {

    .MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th {
      border: none !important;
    }
    .MuiTableRow-root:last-child th {
      border-bottom: 1px solid rgb(224, 224, 224) !important;
      font-weight: bold;
    }
  }
}

.scoresheet-vs-header {
  margin: 1em auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  h3, p {
    white-space: nowrap;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }

  .player-header {
    padding: 0 1em;
    background-color: #f4f4f4;
    width: 50%;
    overflow: hidden;
    position: relative;
  }

  .p1-header {
    text-align: left;

    .fade {
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      right: 0;
    }
  }

  .p2-header {
    text-align: right;

    .fade {
      background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      left: 0;
    }
  }

  .fade {
    position: absolute;
    z-index: 2;
    width: 25%;
    height: 100%;
    top: 0;
  }

  .vs {
    font-family: $kaushan-script;
  }
}

.turn-number {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.scores {
  display: flex;
  justify-content: center;
  align-items: center;

  .p1-score, .p2-score {
    margin: 0 1em;
    font-size: 2em;
  }
}

.scoresheet-header {
    width: calc(100% - 2em);
    background-color: #f4f4f4;
    padding:1em;
  }

.selectors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em auto;
    width: 100vw;

    .tab-panel > div {
      padding: 1em;
    }
}

.scoresheets {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .scoresheet {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .MuiTableCell-root {
      padding: 0;
      max-width: 180px;
    }

    .mobileScoresheet {
      box-shadow: 0;

      #standard-number {
        text-align: center;
      }
      .tactic > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1ch;
      }

      .turn {
        width: 2em;
        
        p {
          text-align: center;
        }
      }

      .priority-parent {
        position: relative;
        overflow: hidden;

        // .turn-flag {
        //   background-color: #f4f4f4;
        //   padding: 1ch 0;
        //   text-align: center;
        //   font-weight: bold;
        //   width: 100%;
        //   position: absolute;
        //   top: -40px;
        //   left: 0;
        //   transform: skewY(-45deg);
        //   height: 45px;

        //   p {
        //     transform: skewY(45deg);
        //     position: absolute;
        //     top: 20px;
        //     left: 5px;
        //   }
        // }

        .priority {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
      }
    }
  }

  .scoresheet-open {

    .mobileScoresheet {
      box-shadow: 0;

      .turn {
        width: 4em;
      }
    }
  }
  
  .scoresheet-footer {
    > div {
      display: flex;
      justify-content: space-between;
      padding: 0;
      flex-direction: column;

      button {
        margin-top: 1em;
      }

      .turn-5 {
        pointer-events: none;
        opacity: 0.5;
      }

      .grand-strat {

        > div {
          width: 100%;
          margin-top: 1em;
        }
      }

      .totals {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    
    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .grandStrat {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .grandStrat .check {
      margin-left: 1em;
      width: 22px;
    }
  }
}

// Hide the following turns until the turn has been progressed to
.scoresheets-1 {

  .turn-2, .turn-3, .turn-4, .turn-5 {
    display: none;
  }
}

.scoresheets-2 {
  .turn-2 {
    display: table-row;
  }
  .turn-3, .turn-4, .turn-5 {
    display: none;
  }
}

.scoresheets-3 {
  .turn-2, .turn-3 {
    display: table-row;
  }
  .turn-4, .turn-5 {
    display: none;
  }
}

.scoresheets-4 {
  .turn-2, .turn-3, .turn-4 {
    display: table-row;
  }
  .turn-5 {
    display: none;
  }
}

.scoresheets-5 {
  .turn-2, .turn-3, .turn-4, .turn-5 {
    display: table-row;
  }
}

.scoresheets {
  // .tab-panel.over {
  //   pointer-events: none;
  //   opacity: 0.7;
  // }
}

.start-game {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px dashed #3f3f3f;
  padding: 2em .5em;

  button {
    margin: 0 0 1em 0;
  }
}

.end-game {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px dashed #3f3f3f;
  padding: 2em .5em;
}

.end-game-modal, .end-turn-modal {
  background-color: #f4f4f4;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  box-shadow: 0px 0px 10px #000;
  border-radius: 6px;

  .modal-divider {
    width: 100%;
    border-color: #c1c1c1;
    margin: 1em 0;
  }
}

.end-turn {
  border-top: 1px dashed #3f3f3f;
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;

    button {
      width: 100%;

    }
    .button-turn-5 {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

// Tablet to Desktop
@media (min-width: #{$screen-md-min}) {
  .selectors {
      flex-direction: row;
      width: 50vw;

    > p {
      display: none;
    }
  }
  .scoresheets {
    margin: 4em 0 0;
    flex-direction: row;
    
    .scoresheet {
      margin: 0 2em;
      width: 100%;
    }
  }
}