.account-divider {
    margin: 1em 0 !important;
}

.end-game {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px dashed #3f3f3f;
    padding: 2em .5em;
}

.end-game-modal, .end-turn-modal {
    background-color: #f4f4f4;
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    box-shadow: 0px 0px 10px #000;
    border-radius: 6px;

    .modal-divider {
        width: 100%;
        border-color: #c1c1c1;
        margin: 1em 0;
    }
}