.game-ended {

    #simple-tabpanel-0, #simple-tabpanel-1 {

        .MuiBox-root {
            padding: 1em 0;


            .MuiTableCell-alignLeft {
                background-color: #f3f3f3;
            }

            .MuiTableCell-sizeSmall.strat-right {
                position: relative;
                padding-right: 20px;
                .MuiSvgIcon-root {
                    position: absolute;
                    right: 6px;
                    top: 4px;
                }                
            }
        }
    }
}