.stats-divider {
    width: 100%;
    border-color: #c1c1c1;
    margin: 1em 0;
}

.stats-form {

    .MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th {
        border: none !important;
    }
    .MuiTableRow-root:last-child th {
        border-bottom: 1px solid rgb(224, 224, 224) !important;
        font-weight: bold;
    }
}

.alliance-stats {
    position: relative;

    .MuiTableCell-root {
        border: none;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .stats-body {
        position: relative;
        min-height: 40px;
        .fetching-data {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-right: 1em;
            }
        }
    }

}

.stats-center {
    padding-top: 1em;

    .stats-wrapper {
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        margin: 0 auto;
            
        p {
            text-align: left;
        }
        
        .info {
            background-color: #d0f7f7;
            padding: 1em;
            text-align: left;

            .MuiSvgIcon-root {
                position: relative;
                top: 5px;
                left: -2px;
            }
        }

    }

    .stats-tabs {
        margin-top: 2em;
    }

    .stats {
        text-align: left;

        h2 {
            text-align: left;
        }
        .stat-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .stats-form {
                width: 90vw;
                max-width: 420px;
                margin:0 auto 1em auto;
                padding: 0em 1em 1em;

                .chart-container {
                    ul {
                        padding-left: 2em;
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}

// Tablet to Desktop
@media (min-width: #{$screen-md-min}) {    
    .stats-center {
        .stats {
            .stat-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .stats-form {
                    width: 320px;
                    max-width: none;
                    margin: 0 1em 1em 0;
                    padding: 0em 1em 1em;
                }
            }
        }
    }
}