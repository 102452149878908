.App-footer {
    background-color: #282c34;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(10px + 2vmin);
    color: white;
  
    h1 {
      font-family: $kaushan-script;
      margin: 0.5em;
    }
    small {
      margin-bottom: 0.5em;
      font-size: 12px;
    }
}

@media (min-width: #{$screen-md-min}) {    
  .App-footer {
    flex-direction: row;
  }
}